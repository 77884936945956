import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner
} from 'reactstrap';

const AdditionalChargeForInvite = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }

  const acceptAdditionalCharge = (e) => {
    setLoading(true);
    toggle(e);
    window.location.href = '/users/new';
  }

  return (
    <div>
      <a className="btn btn-purple btn-rounded w-md waves-effect waves-light mb-3" href="#" onClick={toggle}>
        Invite User
      </a>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Additional Charge</ModalHeader>
        <ModalBody>
          <p>
            Each additional user will add $2.00 to your monthly invoice/payment.
            Please click the Accept button to continue adding a new user.
          </p>

          <p>
            After sending an invite on the next page, your new user will receive an email asking
            to confirm the email address and to complete their registration.
          </p>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={acceptAdditionalCharge}>
            {loading ? <Spinner color="light" /> : 'Accept'}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdditionalChargeForInvite;
