import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

const DigitalSignature = (props) => {
  const {
    className,
    title
  } = props;

  const [modal, setModal] = useState(false);
  const sigCanvas = useRef(null)

  const toggle = () => setModal(!modal);

  const saveSignature = () => {
    const block = document.getElementById('digital-signture-img-block')
    var img = document.createElement("img");
    img.setAttribute("height", "49px");
    img.setAttribute("width", "130px");
    block.innerHTML = '';
    block.appendChild(img)
    const data = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    img.src = data;

    const formEl = document.getElementsByName('bill_of_lading[digital_signature]')
    formEl[0].setAttribute('value', data);

    toggle()
  }

  const clearSignature = () => {
    sigCanvas.current.clear()
  }

  return (
    <div className="digital-signature-block">
      <a className="link add-digital-signature" onClick={toggle}>
        <i className="far fa-plus-square"></i>
      </a>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <p>Please do sign with your mouse, and then press <b>"Add"</b> button</p>
          <div style={{border: '1px solid black'}}>
            <SignatureCanvas 
              ref={sigCanvas}
              penColor='black'
              // backgroundColor="rgba(248,246,246,1)"
              canvasProps={{width: "468", height: 200, className: 'sigCanvas'}}
            />
          </div>
          <div className='text-center'>
            <Button color="link" onClick={clearSignature}>Clear Signature</Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveSignature}>Add</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DigitalSignature;
