import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Label
} from 'reactstrap';
import Select from 'react-select';

const AddCarrierModal = (props) => {
  const {
    className,
    carriers,
    textAreaId,
    title
  } = props;

  const [modal, setModal] = useState(false);
  const [carrier, setCarrier] = useState(carriers[0]);

  const toggle = () => setModal(!modal);

  const selectCarrier = () => {
    const textArea = document.getElementById(textAreaId);
    textArea.value = carrier;
    toggle()
  }

  return (
    <div>
      <a className="link" onClick={toggle}>
        <i className="far fa-plus-square"></i>
      </a>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="exampleSelect">Select Carrier</Label>
            <Select
              options={carriers.map((value) => ({
                value: value,
                label: value,
              }))}
              onChange={(option) => setCarrier(option.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={selectCarrier}>Add</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddCarrierModal;
