import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

const CalculatorModal = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  }

  useEffect(() => {
    document.getElementById('freight-class-calculator-trigger').addEventListener('click', toggle);
  }, []);

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.inchcalculator.com/e/widgets.min.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }, 300);
    }
  }, [modal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Freight Class Calculator</ModalHeader>
        <ModalBody>
          <div id="inch-calculator-icw" data-ct="freight_class" data-cw="100%" data-ch="850" data-cv="MTE3MDkzMTQ4MzQ=">
            <div id="inch-calculator-icwh"></div>
            <div id="inch-calculator-icwf">
              <a id="inch-calculator-icwi" href="https://www.inchcalculator.com/freight-class-calculator/" target="_blank">
                <img id="inch-calculator-icwl" src="https://cdn.inchcalculator.com/e/inch-calculator-logo-tiny.png" alt="Inch Calculator Logo" width="40" height="49"/>
                <span id="inch-calculator-icwb">Inch Calculator</span>
              </a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CalculatorModal;
