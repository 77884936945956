import React, { useState, useEffect } from 'react';

const Logo = (props) => {
  const { logo, editable } = props;

  const [logoUrl, setLogoUrl] = useState(logo)

  useEffect(() => {
    const elem = document.getElementById('bill_of_lading_logo')
    elem.addEventListener('change', handleChange);
  })

  const handleChange = (e) => {
    const uploadedImageUrl = URL.createObjectURL(e.target.files[0])
    setLogoUrl(uploadedImageUrl)
    document.getElementById('add_company_logo').checked = false;
    document.getElementById('remove_logo').checked = false;

    return { value: e.target.files };
  }

  const addFile = (e) => {
    e.preventDefault();
    document.getElementById('bill_of_lading_logo').click();
  }

  const removeLogo = (e) => {
    e.preventDefault();
    setLogoUrl(null)

    document.getElementById('add_company_logo').checked = false;
    document.getElementById('remove_logo').checked = true;
  }

  const renderUploadBtn = () => {
    if (logoUrl || !editable) return

    return (
      <>
        <button className="btn btn-link" onClick={(e) => addFile(e)}>
          <span className="fa-stack fa-1x">
            <i className="fas fa-circle fa-stack-2x"></i>
            <i className="fas fa-store fa-stack-1x fa-inverse"></i>
          </span>
          Upload your logo
        </button>
      </>
    )
  }

  const renderLogoPreview = () => {
    if (!logoUrl) return

    return (
      <>
        <img src={logoUrl} alt="Logo" style={{ height: '68px' }}/>
        { editable && <a className="link text-danger " onClick={removeLogo}>
          <i className="far fa-minus-square"></i>
        </a>}
      </>
    )
  }

  return (
    <div className="logo-container">
      <div className="bol-form-logo">
        {renderLogoPreview()}
        {renderUploadBtn()}
      </div>
    </div>
  );
}

export default Logo;
