import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts';

const renderLegend = ({ payload }) => {
  return (
    payload.map((entry, index) => (
      <div key={`item-${index}`}>
        <svg width="20" height="15">
          <rect width="20" height="15" style={{fill: entry.color}} />
        </svg>
        <span className="ml-5">{entry.payload.name}</span>
      </div>
    ))
  );
}

const CarrierPieChart = ({ data }) => {
  const COLORS = [
    '#5b69bc',
    '#006400',
    '#b03060',
    '#ff0000',
    '#ffff00',
    '#deb887',
    '#00ff00',
    '#00ffff',
    '#ff00ff',
    '#6495ed',
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart >
        <Pie data={data} dataKey="Used" fill="#8884d8" label>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend content={renderLegend} layout="horizontal" verticalAlign="center"/>
      </PieChart>
    </ResponsiveContainer>
  )
};

export default CarrierPieChart;
