import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Input, Label, FormText, Alert
} from 'reactstrap';
import Select from 'react-select';

const ProductTableTriggers = (props) => {
  const {
    className,
    products,
    title
  } = props;

  const productTableId = 'product-table-body';
  const totalProductNames = [
    'total_handling_unit_qty',
    'total_package_qty',
    'total_weight',
  ];

  const customerOrderTableId = 'customer-order-table-body';
  const totalCustomerOrderNames = [
    'customer_order_total_pkgs',
    'customer_order_total_weight',
  ];

  const countTotalsQty = (tableId, totalName, name) => {
    const totalInput = document.getElementsByName(`bill_of_lading[${totalName}]`)

    const rows = document.getElementById(tableId).rows
    let total = 0;
    for (let row of rows) {
      const index = row.dataset.index
      let element = document.getElementsByName(`bill_of_lading[${name}_${index}]`)
      const qty = parseInt(element[0].value)
      if (qty) {
        total += qty
      }
    }

    totalInput[0].value = total;
  }

  const trigerCountTotals = (totalInputs, tableId, input) => {
    for (let name of totalInputs) {
      const rowName = name.replace('total_', '');
      if (input.name.includes(`bill_of_lading[${rowName}`)) {
        countTotalsQty(tableId, name, rowName)
      }
    }
  }

  const recalculateTotals = (totalInputs, tableId) => {
    const table = document.getElementById(tableId);
    const inputs = table.getElementsByTagName('input');

    for (let input of inputs) {
      input.oninput = function () {
        input.parentElement.parentElement.dataset.emptyRow = false;
        trigerCountTotals(totalInputs, tableId, input);
      };
    }
  };

  useEffect(() => {
    recalculateTotals(totalProductNames, productTableId);
    recalculateTotals(totalCustomerOrderNames, customerOrderTableId);
  }, [])

  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState(products[0]);
  const [pkgQty, setPkgQty] = useState('');
  const [huQty, setHuQty] = useState('');

  const toggle = () => setModal(!modal);

  /* The format of emergency data should be like
    Key type: list of values sepparated by ","
    Examples:
      Emergency Contact: contact1, contact2;
      Emergency Phone Number: number1, number2; */

  const updateEmergencyData = (key, value) => {

    var instructions = document.getElementById("bill_of_lading_special_instructions").value;
    // Looking for emergency key
    var instruction = instructions.split("\n").find((instruction) => {
      return instruction.toLowerCase().startsWith(key.toLowerCase());
    });
    // Key exists -> checking, if existed values is already added or not
    if (instruction) {
      const existedValue = instruction
        .split(":")
        .pop()
        .split(",")
        .find((existed) => existed.trim().replace(/;$/, '') == value);
      // Value does not exist. Removing ";" at the end, add value, add ";"
      if (!existedValue) {
        // Replacing existing instruction with new one with added value!
        instructions = instructions.replace(instruction, `${instruction.replace(/;$/, '')}, ${value};`);
        document.getElementById("bill_of_lading_special_instructions").value = instructions;
      }
    // Key does not exist
    } else {
      // Instractions are not empty. We should add from new line;
      if (instructions && instructions != "") {
        instructions = `${instructions}\n`;
      }
      // Adding emergency instruction;
      instructions = `${instructions}${key}: ${value};`;
      document.getElementById("bill_of_lading_special_instructions").value = instructions;
    }

  };

  const addEmergencyContact = () => {
    if (product.hazard_emergency_contact && product.hazard_emergency_contact != "") {
      updateEmergencyData("Emergency Contact", product.hazard_emergency_contact);
    }
  };

  const addPhoneNumber = () => {
    if (product.hazard_emergency_phone_number && product.hazard_emergency_phone_number) {
      updateEmergencyData("Emergency Phone Number", product.hazard_emergency_phone_number);
    }
  };

  const fillProductRow = () => {
    const emptyRow = document.getElementById('product-table-body')
                             .querySelector("[data-empty-row='true']")
    const index = emptyRow.dataset.index

    for (let [key, value] of Object.entries(fillValues())) {
      let element = document.getElementsByName(`bill_of_lading[${key}${index}]`)
      if (key == 'weight_unit') {
        element = document.getElementsByName(`bill_of_lading[${key}]`)
      }
      element[0].value = value
      if (['handling_unit_qty_', 'package_qty_'].includes(key)) {
        trigerCountTotals(totalProductNames, productTableId, element[0]);
      }
    }
    emptyRow.dataset.emptyRow = false
    if (product.hazardous) {
      addEmergencyContact();
      addPhoneNumber();
    }
    toggle()
  }

  const clearAll = () => {
    const table = document.getElementById('product-table-body');
    const rows = table.rows
    for (let row of rows) {
      const inputs = row.getElementsByTagName('input');
      row.dataset.emptyRow = true;
      for (let input of inputs) {
        input.value = ''
      }
    }

    for (let name of totalProductNames) {
      const totalInput = document.getElementsByName(`bill_of_lading[${name}]`)
      totalInput[0].value = 0;
    }
  }

  const fillValues = () => {
    return {
      'handling_unit_qty_': huQty,
      'handling_unit_type_': product.unit_type,
      'package_qty_': pkgQty,
      'package_type_': product.package_type,
      'package_length_': product.package_length,
      'package_width_': product.package_width,
      'package_height_': product.package_height,
      'weight_unit': product.weight_unit,
      'weight_': product.weight * huQty,
      'hmx_': product.hazardous ? 'x' : '',
      'description_': product.description,
      'ltl_class_': product.freight
    }
  }

  return (
    <div>
      <a className="link add-product-btn" onClick={toggle}>
        <i className="far fa-plus-square"></i>
      </a>
      <a className="link text-danger clear-all-btn" onClick={clearAll}>
        clear all
      </a>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {products.length ? null :
            <Alert color="warning">
              You don't have products yet.
            </Alert>}
          <FormGroup>
            <Label for="exampleSelect">Select From Your Saved Products:</Label>
            <Select
              options={products.map((value) => ({
                id: value.id,
                label: value.title,
              }))}
              onChange={(option) => setProduct(products.find(p => p.id == option.id))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleFile">Handling Unit Qty</Label>
            <Input type="text" defaultValue={huQty} onChange={(e) => setHuQty(e.target.value)}/>
            <FormText color="muted">
              Digits and decimals only (Ex. 30, 1.25)
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for="exampleFile">Package Qty</Label>
            <Input type="text" defaultValue={pkgQty} onChange={(e) => setPkgQty(e.target.value)} />
            <FormText color="muted">
              Digits and decimals only (Ex. 30, 1.25)
            </FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={fillProductRow} disabled={!products.length}>Add</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ProductTableTriggers;
