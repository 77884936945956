(function () {
  'use strict';
  window.addEventListener('turbolinks:load', function () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      var inputs = form.elements;
      // Iterate over the form controls
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].nodeName === "INPUT") {
          // Update text input
          inputs[i].addEventListener('input', (e) => {
            e.target.classList.remove('is-invalid');
            var messages = e.target.parentElement.getElementsByClassName('text-danger')
            for (let r = 0; r < messages.length; r++) {
              messages[r].hidden = true
            }
          })
        }
      }
      // form.addEventListener('submit', function (event) {
      //   if (form.checkValidity() === false) {
      //     event.preventDefault();
      //     event.stopPropagation();
      //   }
      //   form.classList.add('was-validated');
      // }, false);
    });
  }, false);
})();
