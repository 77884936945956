import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const SearchByDate = ({ val }) => {
  const [value, setValue] = useState(val ? new Date(val) : null);

  return (
    <DatePicker 
      name="q[date_eq]"
      dateFormat="MM/dd/yyyy"
      placeholderText="Date"
      selected={value}
      onChange={(date) => setValue(date)}
      className="form-control mx-2"
      isClearable={true}
    />
  );
};

export default SearchByDate;
