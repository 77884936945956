const hideHazardsFields = () => {
  const hazardous = document.getElementById('product_hazardous');
  const hazardFieldCalss = 'hazard-field';
  if (!hazardous) {
    return
  }
  hazardous.addEventListener('change', (event) => {
    const fields = document.getElementsByClassName(hazardFieldCalss);
    for (var i = 0; i < fields.length; i++) {
      if (event.target.value == 'true') {
        fields[i].classList.remove("d-none");
      } else {
        fields[i].classList.add('d-none')
      }
      // fields[i].style.display = event.target.value == 'true' ? "flex" : "none";
    }
  });
}
const Products = {
  init: () => {
    hideHazardsFields();
  }
}

export default Products;
