import Rails from '@rails/ujs';

var createCheckoutSession = function () {
  return fetch("/subscription/create_checkout_session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-Token': Rails.csrfToken()
    }
  }).then(function (result) {
    if (result.ok) {
      return result.json();
    } else {
      return { error: { message: result.statusText } }
    }
  });
};

var handleResult = function (result) {
  if (result.error) {
    var displayError = document.getElementById("error-message");
    displayError.textContent = result.error.message;
  }
};

export const stripeInitCheckout = () => {
  const buttonId = 'subscribe_strip_basic'
  const element = document.getElementById(buttonId)
  if (element) {
    element.addEventListener("click", (evt) => {
      const spinner = evt.target.getElementsByClassName('spinner-border')[0];
      spinner.classList.remove('d-none');
      createCheckoutSession().then((data) => {
        if (data.error) {
          spinner.classList.add('d-none');
          var displayError = document.getElementById("error-message");
          displayError.textContent = data.error.message;
        } else {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            })
            .then(handleResult);
        }
      });
    });
  }
}
