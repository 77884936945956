import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const DateInput = ({ val, name, className }) => {
  const [value, setValue] = useState(val ? new Date(val) : null);

  return (
    <DatePicker 
      name={name}
      dateFormat="MM/dd/yyyy"
      selected={value}
      onChange={(date) => setValue(date)}
      className={className}
      id="bill_of_lading_date"
    />
  );
};

export default DateInput;
