import React, { useState } from 'react';

const ClearProductLineButton = (props) => {
  const {
    productRowId
  } = props;


  const clearRow = () => {
    const row = document.getElementById(productRowId);
    const inputs = row.getElementsByTagName('input');
    for (let input of inputs) {
      input.value = ''
    }
    row.dataset.emptyRow = true;
  }

  return (
    <div>
      <a className="link text-danger" onClick={clearRow}>
        <i className="far fa-minus-square"></i>
      </a>
    </div>
  );
}

export default ClearProductLineButton;
