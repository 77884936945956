import Products from './products'
import ShipmentForm from './shipmentForm'
import ImagePreview from './imagePreview'
import { stripeInitCheckout } from './stripeSubscription'

// document.addEventListener('DOMContentLoaded', () => {
//   Products.init();

// });

document.addEventListener('turbolinks:load', () => {
  Products.init();
  ImagePreview.init();
  ShipmentForm.init();
  stripeInitCheckout();
});
