const addPreview = (e) => {
  const preview = URL.createObjectURL(e.target.files[0]);
  const previewBlocks = e.target.parentElement.getElementsByClassName('preview');

  if (previewBlocks.length) {
    const block = previewBlocks[0];
    let elem = document.createElement("img");
    elem.classList.add('img-thumbnail');
    block.innerHTML = "";
    block.appendChild(elem);
    elem.src = preview;
  }
}

const ImagePreview = {
  init: () => {
    const imageFields = document.getElementsByClassName('image_field');
    if (imageFields.length) {
      for (let field of imageFields) {
        field.addEventListener('change', (e) => addPreview(e));
      }
    }
  }
}
export default ImagePreview;
