import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

// we need to make this component reusable and pass the items with links
const ButtonWithDropdown = ({ bolLink, palletLabelA4Link, palletLabelA5Link }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const download = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
      <DropdownToggle className='btn btn-purple btn-rounded'>
          <span className="ml-1">
            Download <i className="mdi mdi-chevron-down"></i>
          </span>
        </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => download(bolLink)}>
          Bill of Lading
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => download(palletLabelA4Link)}>Pallet Label - Full Sheet</DropdownItem>
        <DropdownItem onClick={() => download(palletLabelA5Link)}>Pallet Label - Half Sheet</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ButtonWithDropdown;
