import React, { useState, useEffect } from 'react';

import JsBarcode from 'jsbarcode';
import { createCanvas } from 'canvas';

const Barcode = (props) => {
  const {
    elementId,
    templateId
  } = props;

  const [barcode, setBarcode] = useState(null);

  const removeElement = (id) => {
    const template = document.getElementById(id);
    if (template) {
      template.remove();
    }
  }

  const generateAndDisplayBarcode = (data) => {
    var canvas = createCanvas();
    let validValue = true
    JsBarcode(canvas, data, 
      { 
        format: "CODE39", 
        valid: (valid) => { validValue = valid }
      }
    )

    const errorElem = document.getElementById(`${templateId}-error`)
    if (validValue) {
      errorElem.classList.add('d-none')
      const newBarcode = canvas.toDataURL();

      removeElement(templateId);
      removeElement(`${templateId}_barcode`);
      const fileInput = document.getElementsByName(`bill_of_lading[${templateId}]`);
      fileInput[0].setAttribute('value', newBarcode);
      setBarcode(newBarcode);
    } else {
      errorElem.classList.remove('d-none')
    }
  }

  const clearBarcode = () => {
    const fileInput = document.getElementsByName(`bill_of_lading[${templateId}]`);
    fileInput[0].setAttribute('value', '');
    setBarcode('');
  }

  const updateBarcode = (value) => {
    if (value == '' || value == null) {
      clearBarcode();
    } else {
      generateAndDisplayBarcode(value);
    }
  };

  useEffect(() => {
    const input = document.getElementById(elementId);
    updateBarcode(input.value);
    input.oninput = function (e) {
      updateBarcode(e.target.value);
    };
  }, [])

  return (
    <React.Fragment>
      {barcode ? <img height="76" src={barcode} /> : null}
    </React.Fragment>
  );
}

export default Barcode;
