import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

const ImportModal = (props) => {
  const { title, url } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);


  return (
    <>
      <a className="btn btn-link w-md waves-effect waves-light mb-3 float-right" onClick={toggle}>Import</a>
      <Modal isOpen={modal} toggle={toggle}>
        <form action={url} enctype="multipart/form-data" method="post">
          <ModalHeader toggle={toggle}>Import {title}</ModalHeader>
          <ModalBody>
            <p>
              The CSV file should have proper column names that match the fields names of
              imported record. 
            </p>
            <p>As a refference you can use the exported CSV file.</p>
            <div className="form-group">
              <label for="file"></label>
              <input class="form-control" type="file" name="file" id="file" accept='.csv'></input>
            </div>
          </ModalBody>
          <ModalFooter>
          <button type="submit" className='btn btn-primary'>Upload</button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

export default ImportModal;
