require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("../helpers/form-validation");
require("../scripts/init");

import Bugsnag from '@bugsnag/js'

Bugsnag.start(
  '9b48a6987f833683b85a476da48aeaf4',
  {
    releaseStage: 'production',
    enabledReleaseStages: ['production']
  }
)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import '../assets/scss/DefaultTheme.scss';

